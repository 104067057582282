import { alphabet } from "./key-alphabet";
export function milliseconds(key) {
    var result = 0;
    for (var i = 0; i < 8; ++i) {
        var index = alphabet.indexOf(key.charAt(i));
        if (index === -1) {
            throw new Error("Unexpected character '" + key.charAt(i) + "'.");
        }
        result = (result * 64) + index;
    }
    return result;
}
