export function randomString(alphabet, length) {
    var buffer = [];
    length = length | 0;
    while (length) {
        var r = (Math.random() * alphabet.length) | 0;
        buffer.push(alphabet.charAt(r));
        length -= 1;
    }
    return buffer.join("");
}
