import { alphabet } from "./key-alphabet";
import { repeat } from "./text-repeat";
export function increment(key) {
    return key.replace(/[^z]z*$/, function (match) {
        var index = alphabet.indexOf(match.charAt(0));
        if (index === -1) {
            throw new Error("Unexpected character '" + match.charAt(0) + "'.");
        }
        return alphabet.charAt(index + 1) + repeat("-", match.length - 1);
    });
}
