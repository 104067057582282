import { alphabet } from "./key-alphabet";
export function decodeLexicographic(key) {
    var value = 0;
    var lengthChar = key.charAt(0);
    if (lengthChar > "_") {
        for (var k = 1; k < key.length; k++) {
            value *= alphabet.length;
            value += alphabet.indexOf(key.charAt(k));
        }
    }
    else {
        for (var k = 1; k < key.length; k++) {
            value *= alphabet.length;
            value += alphabet.length - 1 - alphabet.indexOf(key.charAt(k));
        }
        value = -value;
    }
    return value;
}
