import { alphabet } from "./key-alphabet";
export function encodeLexicographic(value) {
    var result = "";
    if (value === 0) {
        result = "a-";
    }
    else if (value > 0) {
        while (value > 0) {
            var digit = (value % alphabet.length);
            result = alphabet.charAt(digit) + result;
            value -= digit;
            value /= alphabet.length;
        }
        var prefix = alphabet.charAt(result.length + 37);
        result = prefix + result;
    }
    else {
        value = -value;
        while (value > 0) {
            var digit = (value % alphabet.length);
            result = alphabet.charAt(alphabet.length - 1 - digit) + result;
            value -= digit;
            value /= alphabet.length;
        }
        var prefix = alphabet.charAt(37 - result.length);
        result = prefix + result;
    }
    return result;
}
