import { alphabet } from "./key-alphabet";
import { randomString } from "./text-random-string";
var lastTimestamp = 0;
export function key(timestamp, as) {
    if (timestamp === undefined) {
        timestamp = Date.now();
        if (timestamp <= lastTimestamp) {
            timestamp = lastTimestamp + 1;
        }
        lastTimestamp = timestamp;
    }
    if (timestamp instanceof Date) {
        timestamp = timestamp.getTime();
    }
    var result = new Array(9);
    for (var i = 7; i >= 0; --i) {
        result[i] = alphabet.charAt(timestamp % 64);
        timestamp = Math.floor(timestamp / 64);
    }
    if (timestamp !== 0) {
        throw new Error("Unexpected timestamp.");
    }
    switch (as) {
        case "max":
            result[8] = "zzzzzzzzzzzz";
            break;
        case "min":
            result[8] = "------------";
            break;
        default:
            result[8] = randomString(alphabet, 12);
    }
    return result.join("");
}
export function resetLastTimestamp() {
    lastTimestamp = 0;
}
