import { alphabet } from "./key-alphabet";
import { repeat } from "./text-repeat";
export function decrement(key) {
    return key.replace(/[^-]-*$/, function (match) {
        var index = alphabet.indexOf(match.charAt(0));
        if (index === -1) {
            throw new Error("Unexpected character '" + match.charAt(0) + "'.");
        }
        return alphabet.charAt(index - 1) + repeat("z", match.length - 1);
    });
}
