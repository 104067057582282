export function repeat(text, count) {
    var result = "";
    if (typeof text.repeat === "function") {
        result = text.repeat(count);
    }
    else {
        if (count > 0) {
            while (count > 1) {
                if (count & 1) {
                    result += text;
                }
                count >>= 1;
                text += text;
            }
            result = result + text;
        }
    }
    return result;
}
